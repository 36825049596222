
import { Component, Vue } from "vue-property-decorator";
import i18n from "../../plugins/i18n";
import ToastService from "../../services/helper/ToastService";
import Multiselect from "vue-multiselect";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";

import StartBlock from "../../models/sportevent/StartBlock";
import StartBlockService from "../../services/sportevent/StartBlockService";

@Component({
  components: {
    Multiselect,
    datetime: Datetime,
  },
})
export default class StarterComponent extends Vue {
  public startBlock: StartBlock = new StartBlock();
  // Loading
  public loading = true;

  mounted(): void {
    if (
      this.$route.params.id !== undefined &&
      this.$route.params.id !== "new"
    ) {
      StartBlockService.get(this.$route.params.id).then((item: StartBlock) => {
        this.startBlock = item;
        this.loading = false;
      });
    } else {
      this.startBlock.sportevent =
        this.$store.state.localstore_sportevent.sportevent;
      StartBlockService.getAllBySportevent(
        this.$store.state.localstore_sportevent.sportevent.id
      ).then((items: StartBlock[]) => {
        if (items == undefined) {
          this.startBlock.startBlockPosition = 1;
        } else {
          this.startBlock.startBlockPosition = items.length + 1;
        }
        this.loading = false;
      });
    }
  }

  save(): void {
    StartBlockService.save(this.startBlock).then((item: StartBlock) => {
      if (item) {
        ToastService.Success(i18n.tc("labels.saved"), item.name);
        this.startBlock = item;
        this.handleBack();
      }
    });
  }

  handleBack(): void {
    this.$router.back();
  }
}
